<template>
  <div>
    <b-loading :is-full-page="true" :active="course==null"></b-loading>
    <div
          v-if="course != null"
          class="card is-shadowless is-bordered"
          style="border: 2px dashed rgba(0, 0, 0, 0.13); padding: 16px;"
      >
        <h2 class="subtitle has-text-weight-semibold">  <span style="margin-right: 8px">{{
            course.name
          }}</span></h2>
        <p v-html="course.description"></p>
      </div>
  </div>
</template>

<script>
import HomeWrapper from "../components/HomeWrapper";
import pdf from 'vue-pdf'

export default {
  name: "Course",
  components: {HomeWrapper, pdf},
  mounted() {
    this.load();
  },
  data() {
    return {
      course: null,
    };
  },
  methods: {
    async load() {
      this.course = await this.$api.getCourse(this.$route.params.course_id);
      this.$api.sidebar.course = this.course;
      this.$api.setupTracker(this);
      await this.$api.getCourseTime(this);
      this.$forceUpdate();
    }
  }
}
</script>

<style scoped>

</style>
